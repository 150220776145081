<template>
  <div id="revenue-user">
    <div class="heading text-start">
      <div class="left-heading"><h2>Total messages sent to bot</h2></div>
    </div>
    <div class="content">
      <ValidationObserver>
        <div class="table-header row">
          <ValidationProvider
            name="Search project, division"
            v-slot="{ errors }"
            class="input-group search"
            rules="max:255"
          >
            <span class="input-group-text mb-2">
              <SearchIcon />
            </span>
            <input
              type="text"
              class="form-control mb-2"
              placeholder="Search project, division"
              v-model="filter.id"
              @input="debouncedQuery"
            />
            <span class="error text-left f-w3">{{ errors[0] }}</span>
          </ValidationProvider>
          <div class="input-date-group date row align-items-center" >
            <div class="col">
              <CalenderIcon/>
            </div>
            <div class="col">
              <vue-monthly-picker
                v-model="filter.date"
                :monthLabels="MONTH_LABEL"
                placeHolder="YYYY/MM"
                dateFormat="YYYY/MM"
                alignment="left"
                :clearOption="false"
                inputClass="month-picker"
                :max="maxMonth"
                @selected="changeDate"
            >
            </vue-monthly-picker>
            </div>
          </div>
        <div>
      </div>
        </div>
      </ValidationObserver>
      <div class="table-content">
        <table class="table color-8B9DA5 text-start">
          <thead>
            <tr>
              <th scope="col" class="align-middle">Project Name</th>
              <th scope="col" class="align-middle text-center">Date</th>
              <th scope="col" class="align-middle">Details messages</th>
              <th scope="col" class="align-middle">Message link</th>
              <th scope="col" class="align-middle text-center">Remind times</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(message, index) in messageList" :key="index">
              <td class="pt-3 pb-3 ">{{ message.projectName }}</td>
              <td class="pt-3 pb-3 text-center">{{ message.time }}</td>
              <td class="pt-3 pb-3" style="width: 50%">
                <div style="display: flex">
                <span style="display: block; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;flex: 1; width: 10px"> {{ message.content }}</span>
                </div>
              </td>
              <td class="pt-3 pb-3"><a :href="message.link">{{message.link}}</a></td>
              <td class="pt-3 pb-3 text-center">{{ message.remindTimes }}</td>
              <td>
                <button v-on:click="showModal(message)" class="btn"><EyeIcon /></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <BasePaginate
      :current-page.sync="paginate.currentPage"
      :total-page.sync="paginate.total"
      :per-page.sync="paginate.perPage"
      :total-record.sync="paginate.totalRecord"
      @onPageChanged="changePage"
      v-if="paginate.total > 0"
    />
    <div class="modal-edit-announcement">
      <div class="modal fade" :class="{'show': isShowModal}" id="edit-announcement-modal" tabindex="-1"
           aria-labelledby="edit-announcement-modal" aria-hidden="true" role="dialog" >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header"> 
              <span style="font-weight: bold">Message Detail</span>
              <button type="btn" class="close" aria-label="Close" v-on:click="closeModal()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body"><span>{{messageDetail}}</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasePaginate from "@/components/BasePaginate";
import SearchIcon from "@/components/Icon/SearchIcon";
import EyeIcon from "@/components/Icon/EyeIcon";
import * as _ from "lodash";
import { PER_PAGE_NUMBER } from "@/utils/const";
import {MONTH_LABEL} from "@/utils/const";
import moment from "moment";
import VueMonthlyPicker from 'vue-monthly-picker';
import CalenderIcon from "@/components/Icon/CalenderIcon";

export default {
  name: "Mention",
  components: {
    SearchIcon,
    EyeIcon,
    BasePaginate,
    VueMonthlyPicker,
    CalenderIcon
  },
  data() {
    return {
      messageList: [],
      isShowModal: false,
      messageDetail: '',
      paginate: {
        currentPage: 1,
        total: 0,
        totalRecord: 0,
      },
      pointer: 0,
      filter: {
        id: null,
        date: moment(),
      },
      MONTH_LABEL,
      maxMonth: moment(),
    };
  },
  created() {
    this.filter.date = this.$route.query.date || moment(),
    this.getMentionBotList();
  },
  methods: {
    async getMentionBotList() {
      this.$root.$refs.loading.start();
      const params = { order: "DESC" };
      if (this.filter.id !== null && this.filter.id !== "") {
        params.q = this.filter.id;
      } else {
        params.q = undefined;
      }
      if (this.filter.date !== null && this.filter.date !== "") {
        params.time = this.$dayjs(this.filter.date).format('YYYY-MM-DD');
      } else {
        params.time = undefined;
      }
      if (this.filter.user_type !== 1) {
        params.user_type = this.filter.user_type;
      } else {
        params.user_type = undefined;
      }
      params.limit = PER_PAGE_NUMBER;
      if (this.paginate.currentPage) {
        params.page = this.paginate.currentPage;
      }

      if (this.$route.query.division && this.$route.query.division !== '' && this.$route.query.project === null) {
        params.division = this.$route.query.division
      }

      if (this.$route.query.project && this.$route.query.project !== '') {
        params.project = this.$route.query.project
      }
      this.$store
        .dispatch("bot/getMentionBotLogs", params)
        .then((response) => {
          this.$root.$refs.loading.finish();
          this.paginate.currentPage = response.meta.page;
          this.paginate.totalRecord = response.meta.itemCount;
          this.paginate.total = response.meta.pageCount;
          this.messageList = response.data.map((message) => {
            message.time = moment(message.createdAt).format("YYYY-MM-DD");
            message.link = `${message.chatworkRoom}-${message.chatworkId}`
            return message;
          });
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
        });
    },
    changeDate() {
      if (this.filter.date === null) {
        this.filter.date = moment();
      }
      this.getMentionBotList();
    },
    showModal(message) {
      this.isShowModal = true;
      this.messageDetail = message.content;
    },
    closeModal() {
      this.isShowModal = false;
    },
    handleItemClick(item) {
      console.log(item);
    },
    changePage(page) {
      this.paginate.currentPage = page;
      this.getMentionBotList();
    },
    debouncedQuery: _.debounce(function () {
      this.getMentionBotList();
    }, 500),
  },
};
</script>

<style scoped lang='scss'>
@import "@/assets/scss/mention.scss";
</style>
<style lang='scss'>
.vue-monthly-picker .picker .monthItem .item {
    border-top: none !important;
}</style>